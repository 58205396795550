
import { defineComponent, ref } from 'vue';
import SearchBox from '@/components/keyword-searching/Index.vue';
import Filter, { FilterEvent, FilterOption, FilterType } from '@/components/filter/Index.vue';
import { ElMessage } from 'element-plus';
import { usePage, useEnrollments, useKeyword } from '@/composables';
import { formatISOString, formatLocalTime } from '@/utils/format-time';
import { DEFAULT_PAGE_SIZE, IntBoolean, ResponseError, exportEnrollments } from '@/services/api';

import dayjs from 'dayjs';
import download from 'downloadjs';
import { VerifiedStatus, VerifiedStatusType } from '@/interfaces';
import { useRoute } from 'vue-router';
import { eventNames } from 'process';

const FILTER_OPTIONS: FilterOption[] = [
  {
    type: FilterType.SELECTOR,
    label: '年齡驗證狀態',
    placeholder: '請選擇',
    options: [
      {
        label: '已驗證',
        value: VerifiedStatus.VERIFIED
      },
      {
        label: '驗證中',
        value: VerifiedStatus.VERIFYING
      },
      {
        label: '尚未驗證',
        value: VerifiedStatus.NOT_VERIFIED
      },
      {
        label: '驗證失敗',
        value: VerifiedStatus.FAILED
      }
    ]
  },
  {
    type: FilterType.SELECTOR,
    label: '是否為 NFT 會員',
    placeholder: '請選擇',
    options: [
      {
        label: '是',
        value: `${IntBoolean.TRUE}`
      },
      {
        label: '否',
        value: `${IntBoolean.FALSE}`
      }
    ]
  },
  {
    type: FilterType.SELECTOR,
    label: '是否為體驗會員',
    placeholder: '請選擇',
    options: [
      {
        label: '是',
        value: `${IntBoolean.TRUE}`
      },
      {
        label: '否',
        value: `${IntBoolean.FALSE}`
      }
    ]
  },
  {
    type: FilterType.SELECTOR,
    label: '是否為黑名單',
    placeholder: '請選擇',
    options: [
      {
        label: '是',
        value: `${IntBoolean.TRUE}`
      },
      {
        label: '否',
        value: `${IntBoolean.FALSE}`
      }
    ]
  },
  {
    type: FilterType.SELECTOR,
    label: '電子信箱是否已驗證',
    placeholder: '請選擇',
    options: [
      {
        label: '是',
        value: `${IntBoolean.TRUE}`
      },
      {
        label: '否',
        value: `${IntBoolean.FALSE}`
      }
    ]
  }
];

export default defineComponent({
  components: {
    SearchBox,
    Filter
  },
  setup() {
    const { keyword } = useKeyword();
    const { setPageQuery, page, pageSize } = usePage(1, DEFAULT_PAGE_SIZE);
    const eventId = useRoute().params.id as string;
    const eventName = useRoute().params.name as string;
    const eventDateRange = useRoute().params.dateRange as string;
    const filterDateRange = ref<Date[]>(null);

    const startedAt = ref<string>(null);
    const endedAt = ref<string>(null);
    const verifiedStatus = ref<VerifiedStatusType>();
    const isNFTMember = ref<IntBoolean>();
    const isCouponMember = ref<IntBoolean>(null);
    const isBlack = ref<IntBoolean>();
    const isContactMailVerified = ref<IntBoolean>();

    const verifiedStatusText = {
      [VerifiedStatus.VERIFIED]: '已驗證',
      [VerifiedStatus.VERIFYING]: '驗證中',
      [VerifiedStatus.NOT_VERIFIED]: '尚未驗證',
      [VerifiedStatus.FAILED]: '驗證失敗'
    };

    const handleFilterChange = (event: FilterEvent) => {
      verifiedStatus.value = event[0] as VerifiedStatusType || null;
      isNFTMember.value = event[1] || null;
      isCouponMember.value = event[2] || null;
      isBlack.value = event[3] || null;
      isContactMailVerified.value = event[4] || null;
    };

    const { data, isLoading, isFetching } = useEnrollments({
      page,
      pageSize,
      keyword,
      startedAt,
      endedAt,
      verifiedStatus,
      isNFTMember,
      isCouponMember,
      isBlack,
      isContactMailVerified
    }, { id: eventId });

    const handleDateChange = () => {
      if (!filterDateRange.value) {
        startedAt.value = null;
        endedAt.value = null;
        return;
      }
      startedAt.value = formatISOString(filterDateRange.value[0]);
      endedAt.value = formatISOString(filterDateRange.value[1]);
    };

    const handleExcelExport = async() => {
      const params = {
        query: {
          keyword: keyword.value,
          startedAt: startedAt.value,
          endedAt: endedAt.value,
          verifiedStatus: verifiedStatus.value,
          isNFTMember: isNFTMember.value,
          isCouponMember: isCouponMember.value,
          isBlack: isBlack.value,
          isContactMailVerified: isContactMailVerified.value
        },
        id: eventId
      };
      try {
        const data = await exportEnrollments(params);
        download(data, `${eventName}活動報名資料${eventDateRange.replace(/\s/g, '')}.csv`, 'text/csv');
      } catch (error) {
        ElMessage.error((error as ResponseError).response?.data.message);
      }
    };

    return {
      page,
      keyword,
      handleFilterChange,
      FILTER_OPTIONS,
      data,
      isLoading,
      isFetching,
      filterDateRange,
      setPageQuery,
      handleDateChange,
      handleExcelExport,
      formatLocalTime,
      verifiedStatusText
    };
  }
});
